import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { NavItem, NavButton } from './components';
import AkygaLogo from "static/akyga.webp";
import AkygaDarkLogo from 'static/logo_dark.webp';
import { useTranslation } from 'react-i18next';
import { getBasketFromLocalStorage } from 'Utilities/localStorage';
import { useNavigate } from 'react-router-dom';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { main: mainPages } = pages;

  const [basket, setBasket] = useState(getBasketFromLocalStorage());
  const [highlight, setHighlight] = useState(false);
  useEffect(() => {
    const handleBasketChange = () => {
      setBasket(getBasketFromLocalStorage());
      setHighlight(true);
      setTimeout(() => setHighlight(false), 300);
    };

    window.addEventListener('basketChange', handleBasketChange);
    return () => window.removeEventListener('basketChange', handleBasketChange);
  }, []);

  const itemCount = Object.keys(basket).length;
  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={1}>
      <Box display={'flex'} component="a" href="/" title="Akyga" width={{ xs: 100, md: 120 }}>
        <Box
          component={'img'}
          src={mode === 'light' && !colorInvert ? "https://storage.googleapis.com/mniahapp-static-dev/ak/akyga_logo.png" : AkygaDarkLogo}
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {mainPages.map((page, index) => (
          page.items ? (
            <Box key={index} marginLeft={4}>
              <NavItem title={t(page.title)} id={page.id} items={page.items} colorInvert={colorInvert} />
            </Box>
          ) : (
            <Box key={index} marginLeft={4}>
              <NavButton title={t(page.title)} id={page.id} url={page.href} colorInvert={colorInvert} />
            </Box>
          )
        ))}
        <Box marginLeft={4} sx={{ position: 'relative' }}>
          <Badge badgeContent={itemCount} color="error">
            <Button
              variant="contained"
              color="primary"
              component="a"
              target="blank"
              onClick={() => navigate('/basket')}
              size="medium"
              sx={{
                animation: highlight ? 'pulse 0.6s' : 'none',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.05)' },
                  '100%': { transform: 'scale(1)' },
                },
              }}
            >
              {t('topbar.getQuote')}
            </Button>
          </Badge>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label={t('topbar.menu')}
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
            color: colorInvert ? theme.palette.background.paper : theme.palette.primary.main
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool
};

export default Topbar;
