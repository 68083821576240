import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import AkygaLogo from "static/akyga.webp";
import AkygaDarkLogo from "static/logo_dark.webp";

import NavItem from './components/NavItem';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display="flex"
          component="a"
          href="/"
          title="Home"
          width={{ xs: 100, md: 120 }}
          marginBottom={2}
        >
          <Box
            component="img"
            src={mode === 'light' ? AkygaLogo : AkygaDarkLogo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2}>
        {pages.main.map((page, index) => (
          <NavItem
            key={index}
            title={page.title}
            items={page.items}
            href={page.href}
          />
        ))}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
