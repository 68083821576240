import React, { useState } from "react";
import axios from "axios";
import { useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "components/Container";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from "../../../../config/config";
import useProductData from "Hooks/useProductData";

const SearchBoxHero = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState('');
  const filteredData = useProductData(null, null, null, searchInput);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });

  const handleChange = async (event, value) => {
    if (!value) return;

    const partNumber = value.params.PartNumber;
    const groupId = value.groupid;
    try {
      const response = await axios.get(`${config.API_BASE_URL}/group/${groupId}/name`);
      const groupName = response.data.groupName;
      navigate(`products/${groupName}/list?partNumber=${partNumber}`);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleSearchChange = (event, value) => {
    setSearchInput(value || '');
  };

  return (
    <Box
      paddingTop={"16vh"}
      minHeight={600}
      height={"auto"}
      position={"relative"}
      sx={{
        backgroundColor: theme.palette.alternate.main,
        background:
          "url(https://storage.googleapis.com/mniahapp-static-dev/ak/background.jpg) no-repeat center",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha(theme.palette.primary.dark, 0.1),
          zIndex: 1,
        }}
      />
      <Container position={"relative"} zIndex={2}>
        <Box>
          <Box marginBottom={4}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: 700,
                color: "common.white",
              }}
            >
              {t("searchBoxHero.title")}
            </Typography>
            <Typography
              color="text.primary"
              maxWidth={"655px"}
              sx={{
                fontWeight: 400,
                color: "common.white",
              }}
            >
              {t("searchBoxHero.subtext_1")}
              <br />
              {t("searchBoxHero.subtext_2")}
            </Typography>
          </Box>
          <Autocomplete
            disableClearable
            filterOptions={filterOptions}
            options={filteredData || []}
            getOptionLabel={(option) =>
              option ? `${option.params.Description} (${option.params.ItemCode})` : ""
            }
            onInputChange={handleSearchChange}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <SearchIcon sx={{ marginRight: "0.5rem" }} />
                    {t("searchBoxHero.serachBoxLabel")}
                  </Typography>
                }
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
            style={{
              backgroundColor: theme.palette.alternate.main,
              opacity: 1,
              borderRadius: "10px",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default SearchBoxHero;
