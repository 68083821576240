import { useState, useEffect } from "react";
import { getCategoriesApi } from '../API/tableAPI/groupAPI';

const useCategoryGroups = () => {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState([]);


    useEffect(() => {
      async function fetchData() {
          let groups = await getCategoriesApi();
          setData(groups);
  
          const aa = groups.filter(x => x.isTopLevel).map(x => x.id);
          const topLevelGroups = groups.filter(x => !x.parentId)
          let zz = topLevelGroups.map(topGroup => ({
            label: topGroup.name,
            ids: groups
              .filter(subGroup => subGroup.parentId === topGroup.id)
              .map(subGroup => subGroup.id)
          }));
          
          console.log(zz);
          const updatedFilters = [
            { label: 'trending', ids: aa},
            ...zz,
            { label: 'all', ids: groups.filter(g => g.isLeaf).map(g => g.id)}
          ];
          setFilters(updatedFilters);
      }
      fetchData(); 
  }, []);

    return filters;
};

export default useCategoryGroups;
