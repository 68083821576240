/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Grid, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your last name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  company: yup
    .string()
    .trim()
    .min(1, 'Please enter a valid company name')
    .max(50, 'Please enter a valid company name'),
  message: yup
    .string()
    .trim()
    .max(1000, 'The message cannot contain more than 1000 characters')
    .required('Message is required')
});

const Form = ({ formData, setFormData, handleSubmit }) => {
  const localStorageKey = 'formData';
  const { t } = useTranslation();
  const initialValues = JSON.parse(localStorage.getItem(localStorageKey)) || formData;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setFormData(formik.values);
    localStorage.setItem(localStorageKey, JSON.stringify(formik.values));
  }, [formik.values]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label={t("basket.form.firstName")}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            sx={{
              '& .MuiInputBase-root': {
                height: '56px'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            label={t("basket.form.lastName")}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            sx={{
              '& .MuiInputBase-root': {
                height: '56px'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label={t("basket.form.email")}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{
              '& .MuiInputBase-root': {
                height: '56px'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="company"
            name="company"
            label={t("basket.form.company")}
            value={formik.values.company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
            sx={{
              '& .MuiInputBase-root': {
                height: '56px'
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="message"
            name="message"
            label={t("form.message")}
            multiline
            rows={4}
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            sx={{
              '& .MuiInputBase-root': {
                minHeight: '160px'
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;
