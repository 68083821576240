import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const aboutUsContent = [
  {
    title: 'About us',
    imageSrc: '/assets/aboutus.jpg',
    description: `aboutUs.components.first`
  },
  {
    title: 'Discover our products',
    imageSrc: '/assets/discover.png',
    description: `aboutUs.components.second`
  },
  {
    title: 'Work with our team',
    // imageSrc: '/assets/ourteam.jpg',
    imageSrc: '/assets/rekaSama.png',
    description: `aboutUs.components.third`
  }
];

const AboutUs = () => {
  const theme = useTheme();
  const navigate = useNavigate(); 
  const { t } = useTranslation();

  const navigateToMoreInfo = () => {
    navigate('/about-us');
  };

  return (
    <Box
      paddingY={6}

    >
      <Helmet>
        <title>{t('aboutUs.pageTitle')}</title>
        <meta name="description" content={aboutUsContent[0].description} />
      </Helmet>
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          marginBottom: theme.spacing(4)
        }}
      >
        {t('aboutUs.title')}
      </Typography>
      <Grid container spacing={3}>
        {aboutUsContent.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Box
              component="img"
              src={item.imageSrc}
              alt={`About us image ${index + 1}`}
              sx={{
                borderRadius: 2,
                width: '100%',
                height: '450px',
                boxShadow: theme.shadows[2]
              }}
            />
            <Typography
              variant="h6"
              sx={{
                marginTop: theme.spacing(2),
                fontWeight: 'bold'
              }}
            >
              {t(item.title)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginTop: theme.spacing(2),
                fontWeight: 400,
                lineHeight: 1.5
              }}
            >
              
              {t(item.description)}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="flex-end" marginRight={10}>
      </Box>
    </Box>
  );
};

export default AboutUs;
