import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import Container from 'components/Container';
import Main from "layouts/Main";
import CategoryTile from 'components/CategoryTile';

import config from '../../config/config';

const CataloguePage = () => {
    const [categories, setCategories] = useState([]);
    const [breadcrumbsData, setBreadcrumbsData] = useState([]);
    const theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchCategories = async (idsString) => {
        const endpoint = idsString ? `${config.API_BASE_URL}/tiles/${idsString}` : `${config.API_BASE_URL}/tiles`;
        try {
            const response = await fetch(endpoint);
            if (response.ok) {
                const data = await response.json();
                setCategories(data);

                // Fetch breadcrumbs if we have categories
                if (data.length > 0 && data[0].id) {
                    const breadcrumbsResponse = await fetch(`${config.API_BASE_URL}/group/${data[0].id}/breadcrumbs`);
                    if (breadcrumbsResponse.ok) {
                        const breadcrumbsData = await breadcrumbsResponse.json();
                        setBreadcrumbsData(breadcrumbsData.breadcrumbs);
                    }
                }
            } else {
                console.error('Failed to fetch categories:', response.statusText);
                setCategories([]);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategories([]);
        }
    };

    useEffect(() => {
        fetchCategories(params.id);
    }, [params.id]);

    const handleNavigate = async (e, categoryId) => {
        e.preventDefault();
        
        if (categoryId === 'products') {
            navigate('/products');
            return;
        }

        try {
            // Fetch the category to get its children
            const response = await fetch(`${config.API_BASE_URL}/tiles/${categoryId}`);
            if (response.ok) {
                const category = await response.json();
                if (category && category.length > 0) {
                    // Get all children IDs
                    const childrenIds = category[0].Children?.map(child => child.id).join(',');
                    if (childrenIds) {
                        navigate(`/products/${childrenIds}`);
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching category children:', error);
        }
    };

    const renderCategories = () => {
        return categories.map(category => <CategoryTile key={category.id} category={category} />);
    };

    return (
        <Main>
            <Helmet>
                <title>{t('Catalogue')}</title>
            </Helmet>
            <Container>
                <Box marginTop={4} marginBottom={6}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/products"
                            onClick={(e) => handleNavigate(e, 'products')}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: theme.palette.primary.main,
                                }
                            }}
                        >
                            Products
                        </Link>
                        {breadcrumbsData.map((item) => (
                            <Link
                                key={item.id}
                                underline="hover"
                                color="inherit"
                                href={`/products/${item.id}`}
                                onClick={(e) => handleNavigate(e, item.id)}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                    }
                                }}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </Breadcrumbs>
                </Box>
                <Grid container spacing={4}>
                    {renderCategories()}
                </Grid>
            </Container>
        </Main>
    );
};

// const CategoryTile = ({ category }) => {
//     const theme = useTheme();
//     const navigate = useNavigate();

//     // Assuming 'Children' are part of the category data
//     let childrenIdsString = category.Children?.map(child => child.id).join(',');

//     const goDeeper = () => {
//         if (childrenIdsString) {
//             navigate(`/products/${childrenIdsString}`);
//         } else if (category.isLeaf) {
//             navigate(`/products/${category.name}/list`);
//         }
//     };

//     return (
//         <Grid item xs={12} sm={6} md={4}>
//             <Box
//                 onClick={goDeeper}
//                 style={{
//                     cursor: "pointer",
//                     borderRadius: "8px",
//                     background: theme.palette.background.level1,
//                     boxShadow: theme.shadows[2],
//                     padding: '4%',
//                     transition: 'transform 0.15s ease-in-out',
//                 }}
//                 width={1} 
//                 height={1} 
//                 position={'relative'}
//                 onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//                 onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
//             >
//                 <img
//                     loading="lazy"
//                     style={{
//                         filter: 'brightness(0.7)',
//                         borderRadius: 8,
//                         width: '100%',
//                         height: '100%',
//                         objectFit: 'cover',
//                     }}
//                     src={category.imageSrc} 
//                 />
//                 <Box
//                     position={'absolute'}
//                     bottom={0}
//                     left={0}
//                     right={0}
//                     width={1}
//                     padding={2}
//                     zIndex={2}
//                     style={{
//                         backgroundColor: 'rgba(255, 255, 255, 0.5)',
//                         borderBottomLeftRadius: '8px',
//                         borderBottomRightRadius: '8px',
//                     }}
//                 >
//                     <Typography
//                         color={theme.palette.text.primary}
//                         fontWeight={700}
//                         variant={'h5'}
//                         noWrap
//                     >
//                         {category.name}
//                     </Typography>

//                 </Box>
//             </Box>
//         </Grid>
//     );
// }
export default CataloguePage;
