import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Headline, Form, Partners } from './components';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";


import useSendEmail from "Hooks/useSendContactUsEmail";

const ContactUs = () => {
  const theme = useTheme();
  const { sendEmail, isLoading, isSuccess, error } = useSendEmail();
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    message: '',
  });

  const handleRequestQuote = async () => {
    const formFilled = isFormFilled();
    if (formFilled) {
      try {
        await sendEmail(formData);
        setOpen(true);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          message: '',
        });
      } catch (error) {
        console.error('Error sending email:', error);
      }
    } else {
      alert('Please fill out all required fields before requesting a quote.');
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const isFormFilled = () => {
    return (
      formData.firstName.trim() !== '' &&
      formData.lastName.trim() !== '' &&
      formData.email.trim() !== ''
    );
  };
  return (
      <Main>

      <Box>
        <Box
          sx={{
            backgroundColor: theme.palette.alternate.main,
            backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
            marginTop: -13,
            paddingTop: 13,
          }}
        >
          <Container>
            <Headline />
          </Container>
        </Box>
        <Container maxWidth={800} >
          <Form 
          formData={formData}
            setFormData={setFormData}
            handleSubmit={handleRequestQuote}
            disabled={isLoading}
          />
        </Container>
                      {/* Notyfikacja */}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%", marginTop: "60px" }}>
          Request has been sent!
        </Alert>
      </Snackbar>
      </Box>
    </Main>
  );
};

export default ContactUs;
