const pages = {
  main: [
    {
      title: 'topbar.pages.company.main',
      href: '/',
    },
    {
      title: 'topbar.pages.products',
      href: '/products',
    },
    {
      title: 'topbar.pages.support.certificates',
      href: '/certificates',
    },
    {
      title: 'topbar.pages.support.whereToBuy',
      href: '/distributors',
    },
    {
      title: 'topbar.pages.company.contactUs',
      href: '/contact',
    },
  ],
};

export default pages;
